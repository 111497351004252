import { combineReducers } from 'redux';
import payloadReducer from './payloadReducer';
import utilsReducer from './utilsReducer';
import userReducer from './userReducer';

const rootReducer = combineReducers({
  payload: payloadReducer,
  user: userReducer,
  ...utilsReducer,
});

export default rootReducer;
