import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: ${props => props.theme.font.fontFamily};
    font-style: normal;
    font-size: ${props => rem(props.theme.font.defaultFontSize)};
    line-height: ${props => rem(props.theme.font.defaultLineHeight)};
    color: ${props => props.theme.colors.font};
  }

  button,
  input {
    font-family: ${props => props.theme.font.fontFamily};
    font-style: normal;
    font-size: ${props => rem(props.theme.font.defaultFontSize)};
    line-height: ${props => rem(props.theme.font.defaultLineHeight)};
    color: ${props => props.theme.colors.font};
  }

  p, ul, li, figure, legend, h1, h2, h3, h4, pre {
    margin: 0;
    padding: 0;
  }

  .noScroll {
    position: fixed;
    width: 100%;
  }
`;

export default GlobalStyle;
