import {
  SET_AGREEMENTS,
  SET_STATUS_LEAD_UPDATE,
} from '../actions/utilsActions';

const setAgreements = (state = {}, action) => {
  switch (action.type) {
  case SET_AGREEMENTS:
    return action.payload;
  default:
    return state;
  }
};

const setStatusLeadUpdate = (state = '', action) => {
  switch (action.type) {
  case SET_STATUS_LEAD_UPDATE:
    return action.payload;
  default:
    return state;
  }
};

const utilsReducer = {
  agreements: setAgreements,
  statusLeadUpdate: setStatusLeadUpdate,
};

export default utilsReducer;
