import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import log from 'loglevel';
import { Header, FooterV2 as Footer, PrivacyPolicyPopup } from 'porowneo-ui';
import styled from 'styled-components';

import { utmParametersToSendToDatabase } from '../config/companyConfig';
import { isRadioZetTheme } from '../config/main';
import { BASE_NAME, createUrlCarSession } from '../config/url';
import { appPropTypes } from '../config/propTypes/appPropTypes';
import Results from './Results';
import {
  getParametersFromUrl, getUrlVars, getCookieByName, getBaseName,
} from '../helpers/utils';
import { editUserParam } from '../actions/userActions';
import ModalContainer from './ModalContainer';
import { getFullUrlFromSessionStorage } from '../helpers/UrlHelpers';

export const AppWrapper = styled.div`
  font-family: ${props => props.theme.font.fontFamily};
`;

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modals: {
        needContact: false,
      },
    };
    this.baseName = getBaseName(window.location.pathname, BASE_NAME);
  }

  componentDidMount() {
    this.getLocator();
  }

  getLocator = () => {
    const { dispatch, user } = this.props || {};
    if ((!user || user.locator === '') && dispatch) {
      this.sessionCreate(user, dispatch);
    }
  }

  /**
 * @param {number} expire - time in minutes
 */
  getExpireTime = expire => new Date(new Date().getTime() + (expire * 60 * 1000)).getTime();

  sessionCreate = (user, dispatch) => {
    const { expireTime = 0, locator } = user;
    const nowTime = new Date().getTime();
    const parametersToSend = utmParametersToSendToDatabase;
    const ga = getCookieByName('_ga');

    if (locator === '' || nowTime > expireTime) {
      axios.post(createUrlCarSession(), {
        source: 'house',
        id: null,
        ga,
        referer: getFullUrlFromSessionStorage(),
        ...getParametersFromUrl(getUrlVars, parametersToSend),
      }).then((response) => {
        if (response.status === 200 && response.data.fullLocator !== '') {
          dispatch(editUserParam({
            locator: response.data.fullLocator,
            shortLocator: response.data.shortLocator,
            sessionId: response.data.id,
            expire: response.data.expire,
            expireTime: this.getExpireTime(response.expire),
          }));
        } else {
          log.debug(response);
        }
      });
    }
  }

  toggleModal = (id) => {
    this.setState(prevState => ({
      modals: {
        ...prevState.modals,
        [id]: !prevState.modals[id],
      },
    }));
  }

  render() {
    const {
      state: {
        modals,
      },
      props: {
        user,
        user: {
          shortLocator,
        },
      },
      toggleModal,
      baseName,
    } = this;

    return (
      <AppWrapper>
        <Router basename={baseName}>
          <Header
            locator={shortLocator}
            hiddenTitle
            locatorMargin
            logoLink="https://porowneo.pl/"
            logoLinkTarget="_blank"
          />

          <Switch>
            <Route render={() => (
              <Results
                user={user}
              />
            )}
            />
          </Switch>

          {
            !isRadioZetTheme && (
              <Footer
                additionalLinks={[
                  {
                    linkDesc: 'Kontakt',
                    function: () => toggleModal('needContact'),
                  },
                ]}
              />
            )
          }

          <PrivacyPolicyPopup />

          {modals.needContact && (
            <ModalContainer
              modalId="needContact"
              modalSource="house"
              shortLocator={shortLocator}
              closeModal={toggleModal}
            />
          )}
        </Router>
      </AppWrapper>
    );
  }
}

App.propTypes = appPropTypes;

const mapStateToProps = ({ payload, user }) => ({
  step: payload.step,
  user,
  ...payload,
});

export default connect(mapStateToProps)(App);
