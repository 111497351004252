import styled from 'styled-components';
import { rem } from 'polished';
import {
  media,
} from 'styled-bootstrap-grid';
import { Button } from 'porowneo-ui';

export const ModalWrapper = styled.div`
  box-shadow: 0px 50px 50px rgba(32, 47, 70, 0.03);
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
  padding: 29px 24px 40px;


  ${media.md`
    padding: 41px 40px 40px;
  `}
`;
ModalWrapper.displayName = 'ModalWrapper';

export const ModalH1 = styled.h1`
  margin: 0;
  font-weight: bold;
  text-align: center;
  font-size: ${rem('20px')};
  line-height: ${rem('24px')};

  ${props => props.contact && `
    margin-bottom: 37px;
  `}

  ${media.md`
    font-size: ${rem('24px')};
    line-height: ${rem('32px')};

    ${props => props.contact && `
      margin-bottom: 33px;
    `}
  `}
`;
ModalH1.displayName = 'ModalH1';

export const ModalH4 = styled.h4`
  margin: 0;
  text-align: center;
`;
ModalH4.displayName = 'ModalH4';

export const ModalH5 = styled.h5`
  margin: 0;
  text-align: center;
  color: ${props => (props.error ? props.theme.colors.error : props.theme.colors.primary)};
`;
ModalH5.displayName = 'ModalH5';

export const ModalButton = styled(Button)`
  && {
    margin-top: 32px;

    ${props => props.smallText && `
      font-size: ${rem('12px')};
    `}

    ${media.md`
      && {
        ${props => props.smallText && `
          font-size: ${rem('16px')};
        `}
        }
    `}

  }
`;
ModalButton.displayName = 'ModalButton';
