export const agreementsStatus = (requiredAgreements, userAgreements = {}) => {
  if (requiredAgreements && userAgreements &&
        typeof requiredAgreements === 'object' &&
        typeof userAgreements === 'object' &&
        requiredAgreements.length > 0 &&
        Object.keys(userAgreements).length > 0) {
    let status = true;
    requiredAgreements.forEach((agree) => {
      if (!Object.keys(userAgreements).includes(agree)) {
        status = false;
      }
      if (userAgreements[agree] === false) {
        status = false;
      }
    });
    return status;
  }
  return false;
};

export const addDomainToAgreements = (string) => {
  if (string === '' || string === null || string === undefined) {
    return null;
  }
  const toReplace = ' href="https://porowneo.pl/';
  const toReplace2 = ' href=\'https://porowneo.pl/';
  const toReplace3 = ' href="https://porowneo.pl"';
  const regExp = / href=["]([^"]*)\//g;
  const regExp2 = / href=[']([^"]*)\//g;
  // eslint-disable-next-line max-len
  const regExp3 = / href=""| href=''| href="https:\/\/porowneo.pl\/porowneo.pl"| href='https:\/\/porowneo.pl\/porowneo.pl'| href="https:\/porowneo.pl\/porowneo.pl"| href='https:\/porowneo.pl\/porowneo.pl'| href="porowneo.pl"| href='porowneo.pl'| href="porowneo"| href='porowneo'/g;
  return string.replace(regExp, toReplace).replace(regExp2, toReplace2).replace(regExp3, toReplace3);
};

export const numberWithSpaces = (numberToFormat) => {
  const parts = numberToFormat.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};
