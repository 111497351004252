import axios from 'axios';
import log from 'loglevel';
import { getFullUrlFromSessionStorage } from './UrlHelpers';
import { setStatusLeadUpdate } from '../actions/utilsActions';
import {
  createUrlUserUpdate,
  axiosConfigWithSession,
} from '../config/url';

// eslint-disable-next-line import/prefer-default-export
export const userUpdate = (data, dispatch) => {
  log.debug('userUpdate', data);
  axios
    .post(createUrlUserUpdate(), {
      ...data,
      from_fend: true,
      referer: getFullUrlFromSessionStorage(),
    }, axiosConfigWithSession(data.sessionId))
    .then(({ data: response }) => {
      if (response && typeof response === 'object' && response.msg && response.msg === 'done') {
        dispatch(setStatusLeadUpdate(response.msg));
      } else {
        dispatch(setStatusLeadUpdate('failed'));
      }
    });
};
