import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  PartnerLogo, VARS, above, PartnerGroupTooltip,
} from 'porowneo-ui';
import { companyPhoneNumber } from '../../config/companyConfig';

const ContactBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.reedBackground};
  width: 100%;
  height: 134px;
  padding: 24px 24px 15px 24px;

  ${above(VARS.breakpoints.md)} {
    padding: 24px 24px 24px 32px;
    height: 100px;
  }
`;
ContactBox.displayName = 'ContactBox';

const PhoneInfo = styled.div`
  display: flex;
  min-width: 60%;

  ${above(VARS.breakpoints.md)} {
  min-width: 80%;
  }
`;
PhoneInfo.displayName = 'PhoneInfo';

const ConsultantImg = styled.img`
  display: none;

  ${above(VARS.breakpoints.md)} {
    display: block;
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
`;
ConsultantImg.displayName = 'ConsultantImg';

const Box = styled.div``;
Box.displayName = 'Box';

const ContactTitle = styled.div`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  margin: 0 0 10px 0;
  width: 150px;

  ${above(VARS.breakpoints.md)} {
    width: 100%;
    line-height: ${rem('16px')};
  }
`;
ContactTitle.displayName = 'ContactTitle';

const ContactLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.font};
  font-weight: bold;
  font-size: ${rem('20px')};
  text-decoration: none;
`;
ContactLink.displayName = 'ContactLink';

const PartnerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContactInfoBox = ({ name }) => (
  <ContactBox>
    <PhoneInfo>
      <ConsultantImg
        src={`${process.env.PUBLIC_URL}/img/consultant.png`}
        alt="consultant"
      />
      <Box>
        <ContactTitle>Skontaktuj się z naszym konsultantem</ContactTitle>
        <ContactLink href={`tel:${companyPhoneNumber}`}>
          {companyPhoneNumber}
        </ContactLink>
      </Box>
    </PhoneInfo>
    {name && (
      <PartnerBox>
        <PartnerLogo
          partnerName={name}
          maxWidth="90px"
          maxHeight="42px"
          maxMobileWidth="90px"
          maxMobileHeight="32px"
        />
        <PartnerGroupTooltip partner={name} />
      </PartnerBox>
    )}
  </ContactBox>
);

export default ContactInfoBox;
