import React from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import { PartnerLogo, PartnerGroupTooltip } from 'porowneo-ui';
import { translateCovers } from '../../helpers/utils';
import { numberWithSpaces } from '../../helpers/formUtils';
import { checkOfferPushToDataLayer, leaveContactPushToDataLayer } from '../../helpers/tagManager';
import { saveSaleInfo } from '../../helpers/apiRequests';
import {
  ResultsRow,
  ResultsCol,
  ResultButton,
  CheckedMark,
  UnCheckedMark,
  CoverName,
  CoverDesc,
  Cover,
  CoverValue,
  ExtraDesc,
  ExtraDescRow,
  ExtraDescDoted,
  PartnerGroupTooltipBox,
} from '../../styled/containers/Results.styled';

const ResultsItem = (props) => {
  const {
    additionalDesc,
    randomCovers,
    randomCoversDetails,
    redirectLink,
    partnerName,
    setOrder,
    theftCovers,
    theftCoversDetails,
    openModal,
    optionalDesc,
    user: {
      locator,
      shortLocator,
      sessionId,
    },
  } = props;

  const renderColCovers = (covers, coversDetails, col) => (
    <>
      {Object.keys(covers).map(cover => (
        <React.Fragment
          key={`${cover}-${partnerName}`}
        >
          {cover && (
            <Cover
              id={`${cover}-${partnerName}-${col}`}
            >
              {covers[cover] ? <CheckedMark /> : <UnCheckedMark />}
              <CoverDesc>
                <CoverName
                  dangerouslySetInnerHTML={{ __html: translateCovers(cover) }}
                />
                <CoverValue>
                  <span>
                    {covers[cover] ? `od ${numberWithSpaces(coversDetails[cover])} zł` : 'Brak'}
                  </span>
                </CoverValue>
              </CoverDesc>
            </Cover>
          )}
        </React.Fragment>
      ))}
    </>
  );

  const renderExtraDescDoted = item => (
    <ExtraDescDoted
      key={item}
    >
      {`${item} `}
    </ExtraDescDoted>
  );

  return (
    <ResultsRow
      order={setOrder}
      resultRow
    >
      <ResultsCol
        extraPadding
        displayCol
        md={2}
      >
        <PartnerLogo
          partnerName={partnerName}
          maxWidth="90px"
          maxHeight="42px"
          maxMobileWidth="114px"
          maxMobileHeight="53px"
        />
        <PartnerGroupTooltipBox>
          <PartnerGroupTooltip partner={partnerName} />
        </PartnerGroupTooltipBox>
      </ResultsCol>
      <ResultsCol
        extraPadding
        noSidePadding
        md={8}
      >
        <ResultsRow
          covers
        >
          <ResultsCol
            covers
            col={12}
            coverBorder
            md={6}
          >
            {renderColCovers(randomCovers, randomCoversDetails, 'first')}
          </ResultsCol>
          <ResultsCol
            covers
            col={12}
            md={6}
          >
            {renderColCovers(theftCovers, theftCoversDetails, 'second')}
          </ResultsCol>
        </ResultsRow>
      </ResultsCol>
      <ResultsCol
        extraPadding
        displayCol
        marginTop
        md={2}
      >
        <ResultButton
          id={`buyButton-${partnerName}`}
          buttonPrimary
          marginBottom
          handleClick={() => {
            saveSaleInfo({
              partnerName,
              locator,
              sessionId,
              guid: 'none',
              total: null,
            });
            checkOfferPushToDataLayer(partnerName);
            window.open(redirectLink);
          }}
        >
          Sprawdź ofertę
        </ResultButton>
        <ResultButton
          id={`buyButton-${partnerName}`}
          buttonSecondary
          handleClick={() => {
            leaveContactPushToDataLayer(partnerName, shortLocator);
            openModal('needContact', { needContactPartner: partnerName });
          }}
        >
          Umów rozmowę
        </ResultButton>
      </ResultsCol>
      <ExtraDescRow>
        {size(additionalDesc) > 0 && (
          <ResultsCol
            extraDesc
            col
          >
            <ExtraDesc>
              {'Dodatkowo: '}
            </ExtraDesc>
            {additionalDesc.map(renderExtraDescDoted)}
          </ResultsCol>
        )}
        {size(optionalDesc) > 0 && (
          <ResultsCol
            extraDesc
            col
          >
            <ExtraDesc>
              {'Opcjonalnie: '}
            </ExtraDesc>
            {optionalDesc.map(renderExtraDescDoted)}
          </ResultsCol>
        )}
      </ExtraDescRow>
    </ResultsRow>
  );
};

ResultsItem.defaultProps = {
  partnerName: 'porowneo',
  randomCovers: {
    property: true,
    equipment: true,
  },
  randomCoversDetails: {
    property: '1000000',
    equipment: '1000000',
  },
  theftCovers: {
    const_elements: true,
    equipment: true,
  },
  theftCoversDetails: {
    const_elements: '1000000',
    equipment: '1000000',
  },
  additionalDesc: [],
  optionalDesc: [],
  redirectLink: 'https://porowneo.pl/',
  user: {},
  setOrder: 1,
};

ResultsItem.propTypes = {
  partnerName: PropTypes.string,
  randomCovers: PropTypes.objectOf(PropTypes.bool),
  randomCoversDetails: PropTypes.objectOf(PropTypes.string),
  theftCovers: PropTypes.objectOf(PropTypes.bool),
  theftCoversDetails: PropTypes.objectOf(PropTypes.string),
  additionalDesc: PropTypes.arrayOf(PropTypes.string),
  optionalDesc: PropTypes.arrayOf(PropTypes.string),
  redirectLink: PropTypes.string,
  user: PropTypes.shape({
    locator: PropTypes.string.isRequired,
    shortLocator: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
  }),
  setOrder: PropTypes.number,
  openModal: PropTypes.func.isRequired,
};

export default ResultsItem;
