import * as fieldsLength from './fieldsLength';
import * as regex from '../regex';
import * as masks from '../masks';

const needContactModalFields = {
  name: {
    id: 'name',
    title: 'Imię',
    name: 'fname',
    autoComplete: 'given-name',
    tooltipMsg: '',
    mask: regex.NAME,
    maxLength: fieldsLength.NAME.max,
    validations: {
      minName: fieldsLength.NAME.min,
      max: fieldsLength.NAME.max,
      required: true,
      name: true,
    },
  },
  surname: {
    id: 'surname',
    title: 'Nazwisko',
    tooltipMsg: '',
    name: 'lname',
    autoComplete: 'family-name',
    mask: regex.SURNAME,
    maxLength: fieldsLength.SURNAME.max,
    validations: {
      minSurname: fieldsLength.SURNAME.min,
      max: fieldsLength.SURNAME.max,
      required: true,
      surname: true,
    },
  },
  phoneLeaveContact: {
    id: 'phoneLeaveContact',
    title: 'Twój numer telefonu komórkowego',
    tooltipMsg: 'Numer telefonu komórkowego składający się z 9 cyfr.',
    label: '+48',
    labelPosition: 'left',
    isInput: true,
    mask: masks.PHONE_MASK,
    name: 'phone',
    autoComplete: 'tel',
    validations: {
      phone: true,
      phoneHLR: true,
      requiredPhone: true,
      phoneForbidden: true,
    },
  },
};

export default needContactModalFields;
