import React from 'react';
import { hydrate, render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
// import thunk from 'redux-thunk';
import { ThemeProvider } from 'styled-components';
import log from 'loglevel';
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid';
import rootReducer from './reducers/rootReducer';
import {
  loadState,
  saveState,
} from './helpers/store/storeUtils';
import GlobalStyle from './styled/GlobalStyle';
import { gridTheme } from './styled/gridTheme';
import theme from './styled/theme';
import AppContainer from './containers/App';
import startRaygunService from './services/raygun';
import { saveFullUrlInSessionStorage } from './helpers/UrlHelpers';

require('dotenv').config();

const isOnlyProduction = process.env.REACT_APP_ENV === 'prod';

const middleware = [];
// const middleware = [thunk];

if (isOnlyProduction) {
  log.disableAll();
  startRaygunService();
} else {
  log.enableAll();
}

saveFullUrlInSessionStorage();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(...middleware)),
);

store.subscribe(() => {
  saveState(store.getState());
});

const rootElement = document.getElementById('root');

const WrappedApp = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BaseCSS />
      <GlobalStyle />
      <GridThemeProvider
        gridTheme={gridTheme}
      >
        <AppContainer />
      </GridThemeProvider>
    </ThemeProvider>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  hydrate(<WrappedApp />, rootElement);
} else {
  render(<WrappedApp />, rootElement);
}
