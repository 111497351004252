import React from 'react';
import {
  Fetch,
  MaskedField,
  ValidationHoc,
  AgreementsContainer,
} from 'porowneo-ui';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import FIELDS from '../../config/fields/needContactModalFields';
import { getAgreements } from '../../config/url';
import { setAgreements } from '../../actions/utilsActions';
import * as regex from '../../config/regex';
import { neededAgreements, requiredAgreements } from '../../config/companyConfig';
import { ModalButton } from '../../styled/components/Modal.styled';

const ContactForm = ({
  handleChange,
  handleSetAgreements,
  handleSetValidation,
  agreements,
  dispatch,
  name,
  surname,
  phoneLeaveContact,
  userAgreements,
  triggerValidation,
  sending,
}) => (
  <div>
    <MaskedField
      {...FIELDS.name}
      onChange={handleChange}
      value={name}
      mask={regex.NAME}
      firstChild
      order={10}
      handleSetValidation={handleSetValidation}
    />

    <MaskedField
      {...FIELDS.surname}
      onChange={handleChange}
      value={surname}
      mask={regex.SURNAME}
      order={20}
      handleSetValidation={handleSetValidation}
    />

    <MaskedField
      {...FIELDS.phoneLeaveContact}
      onChange={handleChange}
      value={phoneLeaveContact}
      order={30}
      handleSetValidation={handleSetValidation}
    />

    <Fetch
      url={getAgreements()}
      component={({ data }) => {
        if (!!data && typeof data === 'object' && Array.isArray(data) && data.length > 0) {
          if (size(agreements) === 0 || !isEqual(agreements, data)) {
            dispatch(setAgreements(data));
          }
        }
        return (
          <AgreementsContainer
            id="needContactAgreements"
            agreements={agreements}
            neededAgreements={neededAgreements}
            handleChange={handleSetAgreements}
            requiredAgreements={requiredAgreements}
            userAgreements={userAgreements}
            startOrder={40}
            handleSetValidation={handleSetValidation}
          />
        );
      }}
    />

    <ModalButton
      handleClick={() => triggerValidation()}
      buttonFullWidth
      buttonPrimary
    >
      {sending ? 'Wysyłanie...' : 'Wyślij'}
    </ModalButton>
  </div>
);

export default ValidationHoc(ContactForm);
