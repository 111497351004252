import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ResultsContainer,
  Results as ResultsStyled,
  ResultsRow,
  ResultsCol,
  ResultTitle,
  ResultsExtra,
  ResultsSortContainer,
} from '../styled/containers/Results.styled';
import ResultsItem from '../components/results/ResultsItem';
import SortItems from '../components/results/SortItems';
import wsResults from '../json/ResultsSample.json';
import { resultHeader, resultExtraDesc } from '../config/companyConfig';
import { translateFrontToBackend } from '../helpers/utils';
import ModalContainer from './ModalContainer';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted: {
        property: {
          active: false,
          sort: 'asc',
        },
        equipment: {
          active: false,
          sort: 'asc',
        },
      },
      secondSorted: {
        theftConstElements: {
          active: false,
          sort: 'asc',
        },
        theftEquipment: {
          active: false,
          sort: 'asc',
        },
      },
      actualSort: {
        sortedBy: '',
        sortGroup: '',
        sort: 'asc',
      },
      modals: {
        needContact: false,
        needContactPartner: '',
      },
    };
  }

  handleSetSort = (key, sortBy, source = 'sorted') => {
    const {
      state: {
        sorted,
        secondSorted,
      },
    } = this;
    const changeSort = (sortType) => {
      if (sortBy.active) {
        switch (sortType) {
        case 'asc':
          return 'desc';
        default:
          return 'asc';
        }
      } else {
        return sortType;
      }
    };

    const newState = {
      sorted,
      secondSorted,
    };

    Object.keys(newState).forEach((sortGroup) => {
      Object.keys(newState[sortGroup]).forEach((sortedKey) => {
        if (key !== sortedKey) {
          newState[sortGroup][sortedKey] = {
            ...newState[sortGroup][sortedKey],
            active: false,
          };
        }
      });
    });

    const newSort = changeSort(sortBy.sort);

    this.setState({
      ...newState,
      [source]: {
        ...newState[source],
        [key]: {
          sort: newSort,
          active: true,
        },
      },
      actualSort: {
        sortedBy: key,
        sortGroup: source === 'sorted' ? 'random_details' : 'theft_details',
        sort: newSort,
      },
    });
  }

  setOrderSortedItems = itemDetail => (itemDetail > 0 ? parseInt(itemDetail, 10) : 1);

  // dump function dell after provide real results
  setDefaultOrder = (partnerName) => {
    switch (partnerName) {
    case 'Aviva':
      return 1;
    case 'Axa':
      return 2;
    case 'TuEuropa':
      return 3;
    case 'Generali':
      return 4;
    case 'Link4':
      return 5;
    case 'MTU':
      return 6;
    case 'Proama':
      return 7;
    default:
      return 0;
    }
  };

  toggleModal = (id, params) => {
    this.setState(prevState => ({
      modals: {
        ...prevState.modals,
        ...params,
        [id]: !prevState.modals[id],
      },
    }));
  }

  render() {
    const {
      state: {
        sorted,
        secondSorted,
        actualSort,
        modals,
      },
      props: {
        resultsTitle,
        user,
      },
      handleSetSort,
      setOrderSortedItems,
      toggleModal,
      setDefaultOrder,
    } = this;

    return (
      <>
        <SortItems
          sortBy={sorted}
          extraSortyBy={secondSorted}
          handleSort={handleSetSort}
          pageHeader={resultHeader}
        />
        <ResultsStyled>
          <ResultsContainer>
            <ResultsRow
              header
            >
              <ResultsCol
                col
              >
                <ResultTitle>
                  {resultsTitle}
                </ResultTitle>
              </ResultsCol>
            </ResultsRow>
            <ResultsSortContainer
              sort={actualSort.sort}
            >
              {wsResults && wsResults.length > 0 && (
                wsResults.map(item => (
                  <ResultsItem
                    key={`result-${item.partner_name}`}
                    partnerName={item.partner_name}
                    randomCovers={item.random}
                    randomCoversDetails={item.random_details}
                    theftCovers={item.theft}
                    theftCoversDetails={item.theft_details}
                    additionalDesc={item.otherDesc}
                    optionalDesc={item.optionalityDesc}
                    redirectLink={item.redirectLink}
                    openModal={toggleModal}
                    user={user}
                    setOrder={
                      actualSort.sortGroup ?
                        setOrderSortedItems(item[actualSort.sortGroup][translateFrontToBackend(actualSort.sortedBy)]) :
                        setDefaultOrder(item.partner_name)
                    }
                  />
                ))
              )}
            </ResultsSortContainer>
            <ResultsRow
              noBackground
              noMarginBottom
            >
              <ResultsCol
                col
              >
                <ResultsExtra>
                  {resultExtraDesc}
                </ResultsExtra>
              </ResultsCol>
            </ResultsRow>
          </ResultsContainer>
        </ResultsStyled>
        {modals.needContact && (
          <ModalContainer
            modalId="needContact"
            modalSource="house"
            closeModal={toggleModal}
            partnerName={modals.needContactPartner}
          />
        )}
      </>
    );
  }
}

Results.defaultProps = {
  resultsTitle: 'OFERTA',
  user: {},
};

Results.propTypes = {
  resultsTitle: PropTypes.string,
  user: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
};
export default Results;
