import axios from 'axios';
import { log } from 'loglevel';
import { sendError } from 'porowneo-ui';

import { createUrlSaveSaleInfo, axiosConfigWithSession } from '../config/url';

export const saveSaleInfo = ({
  guid, total, partnerName, locator, sessionId,
}, type = 'buy', product = 'house') => {
  axios.post(
    createUrlSaveSaleInfo(),
    {
      partnerName,
      locator,
      guid,
      total,
      type,
      product,
    },
    axiosConfigWithSession(sessionId),
  ).then(({ data }) => {
    log.debug(data);
  }).catch((error) => {
    sendError(error, false, {
      method: 'saveSaleInfo',
      component: 'Results',
      url: createUrlSaveSaleInfo(),
    });
  });
};
