export const companyPhoneNumber = '519 902 560';

export const utmParametersToSendToDatabase = ['utm_source', 'utm_medium', 'utm_campaign', 'sub_id'];

export const resultHeader = 'Porównaj oferty ubezpieczeń nieruchomości';

export const companyLinks = [
  {
    address: 'https://www.porowneo.pl/regulamin/?rule=regulamin_porowneo',
    linkDesc: 'Regulamin',
  },
  {
    address: 'https://www.porowneo.pl/sitemap',
    linkDesc: 'Mapa strony',
  },
];

// Agreements config
export const neededAgreements = ['REG_PPL', 'MARK_TEL'];
export const requiredAgreements = ['REG_PPL'];
export const agreementsOrder = {
  REG_PPL: 1,
  MARK_TEL: 2,
};

export const resultExtraDesc = 'Szczegółowe informacje o zakresie ubezpieczenia, w tym o wyłączeniach i ograniczeniach odpowiedzialności, znajdziesz w aktualnych ogólnych warunkach ubezpieczenia nieruchomości, dostępnych na: dla Generali T.U. S.A. na generali.pl, dla Proama (Generali T.U. S.A.) na proama.pl, dla Link4 T.U. S.A. na link4.pl, dla mtu24.pl (Ergo Hestia) na mtu24.pl, dla TU Europa na tueuropa.pl, dla PZU S.A. na pzu.pl, dla UNIQA na uniqa.pl';
