import createReducer from './createReducer';
import { neededAgreements } from '../config/companyConfig';
import {
  EDIT_USER_PARAM,
  RESET_USER_PAYLOAD,
} from '../actions/userActions';

const initialUserAgreements = () => {
  const agreements = {};
  neededAgreements.forEach((agree) => {
    agreements[agree] = false;
  });
  return agreements;
};

export const initialUserPayload = {
  locator: '',
  sessionId: '',
  expire: '',
  expireTime: 0,
  token: '',
  name: '',
  surname: '',
  phoneLeaveContact: '',
  userAgreements: initialUserAgreements(),
};

export const editUserParam = (state, action) => (
  {
    ...initialUserPayload,
    ...state,
    ...action.payload,
  }
);

export const resetUserPayload = () => (
  initialUserPayload
);

const payloadReducer = createReducer(initialUserPayload, {
  [EDIT_USER_PARAM]: editUserParam,
  [RESET_USER_PAYLOAD]: resetUserPayload,
});

export default payloadReducer;
