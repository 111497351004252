/* eslint-disable max-len */
import styled from 'styled-components';
import { rem } from 'polished';
import {
  Container,
  Row,
  Col,
  media,
} from 'styled-bootstrap-grid';
import { Button } from 'porowneo-ui';
import { ReactComponent as CheckMark } from '../../img/checkMark.svg';
import { ReactComponent as UnCheckMark } from '../../img/unCheckMark.svg';

export const Results = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.disabled};
`;

export const ResultsContainer = styled(Container)`
  min-height: 60vh;
  padding: 24px 31px 48px;

  ${media.md`
    padding-top: 63px;
    padding-bottom: 60px;
  `}
`;

export const ResultsRow = styled(Row)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  background-color: ${props => (!props.noBackground ? props.theme.colors.background : 'transparent')};
  min-height: ${props => (!props.resultRow ? 'auto' : '480px')};
  border-radius: 4px;
  order: ${props => props.order || 999999};

  &:nth-child(2) {
    ${media.md`
      border-radius: 0 0 4px 4px;
    `}
  }

  ${props => props.header && `
    display: none;
  `}

  ${props => props.covers && `
    width: 100%;
    min-height: 80px;
    max-height: 100px;
    padding: 0;
    margin: 0;
  `}

  ${props => props.noMarginBottom && `
    margin-bottom: 0;
  `}

  ${media.md`
    min-height: 80px;
    max-height: 300px;

    ${props => (props.resultRow && `
      padding-top: 10px;
    `)};

    ${props => props.header && `
      display: block;
      min-height: 28px;
      background-color: ${props.theme.colors.reedBackground};
      margin-bottom: 0;
      border-radius: 4px 4px 0 0;
    `}
  `}
`;

export const ResultsCol = styled(Col)`
  display: flex;
  flex-direction: ${props => (props.displayCol ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;

  ${props => props.coverBorder && `
    border-bottom: 1px solid ${props.theme.colors.borderColor};
  `}

  ${props => props.marginTop && `
    margin-top: 24px;
  `}

  ${props => props.noSidePadding && `
    padding-right: 0;
    padding-left: 0;
  `}

  ${props => props.covers && `
    justify-content: space-evenly;
    flex-flow: row wrap;
  `}

  ${props => props.extraDesc && `
    padding: 17px 15px 18px;
    margin-top: 10px;
    border-top: 1px solid ${props.theme.colors.borderColor};
    border-bottom: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
  `}

  ${media.md`
    ${props => props.marginTop && `
      margin-top: 0;
    `}

    ${props => props.coverBorder && `
      padding-bottom: 5px;
    `}

    ${props => props.covers && `
      justify-content: space-evenly;
      align-items: stretch;
      flex-wrap: nowrap;
    `}

    ${props => props.extraPadding && `
      padding-top: 16px;
      padding-bottom: 23px;
    `}

    ${props => props.covers && `
      justify-content: space-evenly;
      flex-direction: column;
    `}

    ${props => props.extraPadding && `
        padding-top: 20px;
        padding-bottom: 10px;
    `}

    ${props => props.coverBorder && `
      border-bottom: none;
      border-right: 1px solid ${props.theme.colors.borderColor};
    `}
  `}

  ${media.lg`
    ${props => props.extraPadding && `
        padding-top: 10px;
        padding-bottom: 10px;
    `}


    ${props => props.extraDesc && `
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    `}

    ${props => props.covers && `
      justify-content: space-evenly;
      flex-direction: row;
    `}
  `}

  ${media.xl`
    ${props => props.covers && `
      justify-content: space-evenly;
    `}
  `}
`;

export const PartnerGroupTooltipBox = styled.div`
  margin-top: 16px;

`;

export const ResultTitle = styled.p`
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: ${rem('11px')};
  line-height: ${rem('24px')};
  letter-spacing: 0.18em;
`;

export const ResultButton = styled(Button)`
  && {
    min-width: 100%;
    padding: 0 15px;
    font-weight: bold;
    white-space: wrap;
    border-radius: 4px;
    margin: 0;
    height: 42px;
    max-height: 48px;
    min-height: 48px;
    text-align: center;
    font-size: ${rem('16px')};
    line-height: ${rem('18px')};
    font-family: ${props => props.theme.font.fontFamily};

    ${props => props.marginBottom && `
      margin-bottom: 12px;
    `}
  }

  ${media.md`
    && {
      font-size: ${rem('12px')};
      line-height: ${rem('14px')};
      min-width: 100px;

      ${props => props.marginBottom && `
        margin-bottom: 9px;
      `}
    }
  `}

  ${media.lg`
    && {
      min-width: 140px;
    }
  `}

  ${media.xl`
    && {
      height: 42px;
      max-height: 42px;
      min-height: 42px;
    }
  `}
`;
ResultButton.displayName = 'ResultButton';

const checkMarkSvg = `
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-right: 10px;

  ${media.md`
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
    margin-right: 13px;
  `}
`;
export const CheckedMark = styled(CheckMark)`${checkMarkSvg};`;

export const UnCheckedMark = styled(UnCheckMark)`${checkMarkSvg};`;

export const Cover = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${rem('12px')};
  line-height: ${rem('18px')};
  width: auto;
  margin-right: 14px;
  margin-bottom: 10px;

  ${media.md`
    font-size: ${rem('14px')};
    margin-right: 0;
    margin-bottom: 0;
  `};
`;

export const CoverName = styled.p`
  font-weight: bold;
  white-space: nowrap;

  ${media.md`
    padding-bottom: 3px;
    white-space: pre-wrap;
  `}

  ${media.lg`
    white-space: nowrap;
  `}
`;

export const CoverValue = styled.div`
  display: flex;
  flex-direction: row;

  span {
    white-space: nowrap;
    margin-right: 4px;
  }

  ${media.md`
    flex-direction: column;
  `}

  ${media.lg`
    flex-direction: row;
  `}
`;

export const CoverDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 57px;

  ${media.md`
    min-height: 60px;
  `}

  ${media.lg`
    min-height: 57px;
  `}
`;

export const ResultsExtra = styled.p`
  font-size: ${rem('12px')};
  line-height: ${rem('14px')};
  color: ${props => props.theme.colors.secondaryAccessibility};
  text-align: center;
  padding-top: 30px;
`;

export const ResultsSortContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.sort === 'asc' ? 'column' : 'column-reverse')};
  width: 100%;
`;

export const ExtraDesc = styled.span`
  color: ${props => props.theme.colors.secondaryAccessibility};
  font-size: ${rem('12px')};
  line-height: ${rem('18px')};
`;

export const ExtraDescDoted = styled.span`
  color: ${props => props.theme.colors.secondaryAccessibility};
  font-size: ${rem('12px')};
  line-height: ${rem('18px')};
  background-image: url("data:image/svg+xml,%3Csvg width='4' height='3' viewBox='0 0 4 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2.32812' cy='1.5' r='1.5' fill='%2334BC6E'/%3E%3C/svg%3E%0A");
  background-position: 0 7px;
  background-repeat: no-repeat;
  padding-left: 10px;
`;

export const ExtraDescRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

`;
ExtraDescRow.displayName = 'ExtraDescRow';
