import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { userUpdate } from '../../helpers/sockets';
import { axiosConfigJson, saveAgreements } from '../../config/url';
import * as ACTIONS from '../../actions/userActions';
import { setStatusLeadUpdate } from '../../actions/utilsActions';
import { sendLeadPushToDataLayer } from '../../helpers/tagManager';
import { neededAgreements } from '../../config/companyConfig';
import {
  ModalH1,
  ModalH4,
  ModalH5,
  ModalButton,
} from '../../styled/components/Modal.styled';
import ContactFormWithValidation from './ContactForm';
import ContactInfoBox from './ContactInfoBox';
import Disclaimer from './Disclaimer';

export class NeedContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
    };
  }

  componentDidMount() {
    this.settingLeadUpdate();
  }

  createAgreementsInfoForSaveAgreements = (user, agreements, requiredAgreements) => {
    const agreementsData = [];
    if (agreements && agreements.length > 0) {
      agreements.forEach((agree) => {
        if (requiredAgreements.includes(agree.name)) {
          agreementsData.push({
            id: agree.id,
            value: user.userAgreements[agree.name] || false,
            context: user.sessionId,
            context_type: 'sessionId',
            source: 'house',
          });
        }
      });
    }
    return {
      agreements: agreementsData,
      phone: user.phoneLeaveContact.replace(/\s+/g, ''),
      first_name: user.name,
      last_name: user.surname,
      email: null,
      source: 'porowneo-form-house',
      locatorFull: user.locator || null,
    };
  }

  send = () => {
    const {
      user,
      partnerName,
      agreements,
      modalSource,
      dispatch,
    } = this.props;

    this.setState({ sending: true });

    sendLeadPushToDataLayer(partnerName, user?.shortLocator);

    const agreementsInfo = this.createAgreementsInfoForSaveAgreements(user, agreements, neededAgreements);

    axios.post(saveAgreements(), agreementsInfo, axiosConfigJson);

    userUpdate({
      phone: user.phoneLeaveContact.replace(/\s/g, ''),
      firstname: user.name,
      lastname: user.surname,
      locator_full: user.locator,
      sessionId: user.sessionId,
      source: modalSource,
    }, dispatch);
  };

  handleChange = (key, value) => {
    const { dispatch, user } = this.props;
    const userMod = { ...user };
    if (key in user) {
      userMod[key] = value;
      dispatch(ACTIONS.editUserParam({ ...user, ...userMod }));
    }
    return null;
  }

  handleSetAgreements = (key, value) => {
    const { dispatch, user } = this.props;
    dispatch(ACTIONS.editUserParam({
      ...user,
      userAgreements: {
        ...user.userAgreements,
        [key]: value,
      },
    }));
  }

  settingLeadUpdate = () => {
    const { dispatch, statusLeadUpdate } = this.props;
    const leadStatus = (statusLeadUpdate === 'done' || statusLeadUpdate === 'sent') ? 'sent' : '';
    dispatch(setStatusLeadUpdate(leadStatus));
  }

  setDefaultLeadUpdate = () => {
    const { dispatch } = this.props;
    dispatch(setStatusLeadUpdate(''));
  }

  render() {
    const {
      state: {
        sending,
      },
      props: {
        agreements,
        statusLeadUpdate,
        user: {
          name,
          surname,
          userAgreements,
          phoneLeaveContact,
        },
        closeModal,
        dispatch,
        modalId,
        partnerName,
      },
      handleChange,
      handleSetAgreements,
      send,
    } = this;

    const sentSection = (
      <>
        <ModalH4>
          Rozmowa została już zamówiona. Czy chcesz zamówić kolejną?
        </ModalH4>
        <ModalButton
          handleClick={this.setDefaultLeadUpdate}
          buttonPrimary
          buttonFullWidth
          smallText
        >
          Tak, chcę zamówić kolejną rozmowę
        </ModalButton>
      </>
    );

    const formSection = (
      <>
        <ModalH1
          contact
        >
          Wolisz porozmawiać?
          <br />
          Zostaw kontakt - oddzwonimy!
        </ModalH1>

        <ContactInfoBox
          name={partnerName}
        />

        {
          statusLeadUpdate === 'failed' ?
            (
              <ModalH5
                error
              >
                Przepraszamy, niestety nie udało zapisać się prośby o kontakt.
                <br />
                Możesz spróbować jeszcze raz.
              </ModalH5>
            ) : null
        }

        <ContactFormWithValidation
          handleChange={handleChange}
          handleSetAgreements={handleSetAgreements}
          agreements={agreements}
          dispatch={dispatch}
          callBackIfValid={send}
          name={name}
          surname={surname}
          phoneLeaveContact={phoneLeaveContact}
          userAgreements={userAgreements}
          sending={sending}
        />

        <Disclaimer />
      </>
    );

    const thankYouSection = (
      <>
        <ModalH1>
          Dziękujemy!
          <br />
          Niebawem skontaktujemy się z Tobą.
        </ModalH1>

        <ModalButton
          handleClick={() => closeModal(modalId)}
          buttonFullWidth
          buttonPrimary
        >
          Zamknij
        </ModalButton>
      </>
    );

    switch (statusLeadUpdate) {
    case 'done':
      return thankYouSection;
    case 'sent':
      return sentSection;
    default:
      return formSection;
    }
  }
}

const mapStateToProps = ({
  agreements,
  dispatch,
  statusLeadUpdate,
  user,
}) => ({
  agreements,
  dispatch,
  statusLeadUpdate,
  user,
});

NeedContact.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    locator: PropTypes.string,
    shortLocator: PropTypes.string,
    surname: PropTypes.string,
    name: PropTypes.string,
    userAgreements: PropTypes.object,
    phoneLeaveContact: PropTypes.string,
    sessionId: PropTypes.string,
  }).isRequired,
  statusLeadUpdate: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  agreements: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalId: PropTypes.string.isRequired,
  modalSource: PropTypes.string.isRequired,
  partnerName: PropTypes.string.isRequired,
};
export default connect(mapStateToProps)(NeedContact);
