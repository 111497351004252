import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Portal } from 'porowneo-ui';
import NeedContactModal from '../components/modals/NeedContact';
import {
  ModalWrapper,
} from '../styled/components/Modal.styled';

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowLocation: 0,
    };
  }

  componentDidMount() {
    this.setWindowLocation();
    if (document.querySelector('#root')) document.querySelector('#root').classList.add('noScroll');
  }

  componentWillUnmount() {
    const { windowLocation } = this.state;
    if (document.querySelector('#root')) document.querySelector('#root').classList.remove('noScroll');
    window.scrollTo(0, windowLocation);
  }

  setWindowLocation() {
    this.setState({ windowLocation: window.pageYOffset });
  }

  render() {
    const {
      props: {
        modalId,
        closeModal,
        background,
        modalSource,
        partnerName,
      },
    } = this;

    return (
      <Portal
        containerId="modalPortal"
      >
        <Modal
          id={modalId}
          closeModal={() => closeModal(modalId)}
          isNarrow
          background={background}
        >
          <ModalWrapper>
            {
              modalId === 'needContact' && (
                <NeedContactModal
                  closeModal={closeModal}
                  modalSource={modalSource}
                  modalId={modalId}
                  partnerName={partnerName}
                />
              )
            }
          </ModalWrapper>
        </Modal>
      </Portal>
    );
  }
}

ModalContainer.defaultProps = {
  modalId: 'modalID',
  background: 'primary',
  modalSource: 'house',
  partnerName: '',
};

ModalContainer.propTypes = {
  modalId: PropTypes.string,
  background: PropTypes.string,
  modalSource: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  partnerName: PropTypes.string,
};

export default ModalContainer;
