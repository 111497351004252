export const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component';

export const translateCovers = (cover) => {
  if (typeof cover === 'string' && cover.length > 0) {
    switch (cover) {
    case 'property':
      return 'Nieruchomość <br /> i elementy stałe';
    case 'const_elements':
    case 'constElements':
    case 'theftConstElements':
      return 'Elementy stałe';
    case 'equipment':
    case 'theftEquipment':
      return 'Wyposażenie';
    default:
      return cover.toUpperCase();
    }
  } else {
    return cover;
  }
};

export const translateFrontToBackend = (cover) => {
  switch (cover) {
  case 'constElements':
  case 'theftConstElements':
    return 'const_elements';
  case 'theftEquipment':
    return 'equipment';
  default:
    return cover;
  }
};

export const getParametersFromUrl = (createObjectWithUrlParameters, parametersToFind = []) => {
  const parametersFromUrl = createObjectWithUrlParameters();
  const parametersWithValuesFind = {};
  const parametersFromUrlKeys = Object.keys(parametersFromUrl);

  if (parametersFromUrlKeys.length) {
    if (parametersToFind.length) {
      parametersFromUrlKeys.forEach((parameter) => {
        if (parametersToFind.includes(parameter) && parametersFromUrl[parameter]) {
          parametersWithValuesFind[parameter] = parametersFromUrl[parameter];
        }
        return false;
      });
    } else {
      parametersFromUrlKeys.forEach((param) => {
        parametersWithValuesFind[param] = parametersFromUrl[param];
        return false;
      });
    }
  } else {
    return {};
  }

  return parametersWithValuesFind;
};

export const getUrlVars = () => {
  const vars = {};
  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => {
      vars[key] = value;
    },
  );
  return vars;
};

export const getCookieByName = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');
  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');
    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }
  // Return null if not found
  return null;
};

export const settingAppVersion = (localStorage, appVersion) => {
  const versionKey = 'version_app';
  const varsionAppFromStorage = localStorage.getItem(versionKey);

  if (typeof varsionAppFromStorage === 'undefined' || varsionAppFromStorage === null || localStorage.version_app !== appVersion) {
    localStorage.clear();
    localStorage.setItem(versionKey, appVersion);
    return localStorage.getItem(versionKey);
  }
  return localStorage.getItem(versionKey);
};

export const getBaseName = (currentPath, baseNames) => {
  let newBasName = '/';

  if (Array.isArray(baseNames) && baseNames.length > 0) {
    baseNames.forEach((baseName) => {
      if (currentPath.startsWith(baseName)) {
        newBasName = baseName;
      }
    });
  }

  return newBasName;
};
