import React from 'react';
import PropTypes from 'prop-types';
import {
  SortItemsStyled,
  SortItemsContainer,
  SortButtonContainer,
  SortButton,
  SortRow,
  SortCol,
  PageTitle,
  SortSectionHeader,
} from '../../styled/components/SortItems.styled';
import { translateCovers } from '../../helpers/utils';

const SortItems = (props) => {
  const {
    handleSort,
    sortBy,
    extraSortyBy,
    pageHeader,
  } = props;

  const setOrder = (key) => {
    switch (key) {
    case 'property':
      return 1;
    case 'const_elements':
    case 'theftConstElements':
      return 2;
    case 'equipment':
      return 3;
    default:
      return 999;
    }
  };

  const renderSortItems = (sortedBy, sortType) => (
    <>
      {Object.keys(sortedBy).map(key => (
        <SortButton
          order={setOrder(key)}
          key={`sortBy-${key}`}
          onClick={() => handleSort(key, sortedBy[key], sortType)}
          active={sortedBy[key].active}
          sort={sortedBy[key].sort}
          dangerouslySetInnerHTML={{ __html: translateCovers(key) }}
        />
      ))}
    </>
  );

  return (
    <SortItemsStyled>
      <SortItemsContainer>
        <SortRow
          header
        >
          <SortCol
            col
            header
          >
            <PageTitle>
              {pageHeader}
            </PageTitle>
          </SortCol>
        </SortRow>
        <SortRow
          noBorderTop
          noPaddingTop
        >
          <SortCol
            col={12}
            md={5}
            mdOffset={1}
            lg={4}
            lgOffset={2}
            borderRight
          >
            <SortSectionHeader>
              Pożar i zdarzenia losowe
            </SortSectionHeader>
            <SortButtonContainer>
              {renderSortItems(sortBy)}
            </SortButtonContainer>
          </SortCol>
          <SortCol
            col={12}
            md={5}
            lg={4}
          >
            <SortSectionHeader
              extraMobilePadding
            >
              Kradzież z włamaniem
            </SortSectionHeader>
            <SortButtonContainer>
              {renderSortItems(extraSortyBy, 'secondSorted')}
            </SortButtonContainer>
          </SortCol>
        </SortRow>
      </SortItemsContainer>
    </SortItemsStyled>
  );
};

SortItems.defaultProps = {
  pageHeader: 'Oferty',
  extraSortyBy: {},
};

SortItems.propTypes = {
  handleSort: PropTypes.func.isRequired,
  sortBy: PropTypes.shape({
    active: PropTypes.bool,
    sort: PropTypes.string,
  }).isRequired,
  extraSortyBy: PropTypes.shape({
    active: PropTypes.bool,
    sort: PropTypes.string,
  }),
  pageHeader: PropTypes.string,
};

export default SortItems;
