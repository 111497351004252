const { dataLayer } = window;

export const PARTNER_MAPPING = {
  Aviva: 'aviva',
  Uniqa: 'uniqa',
  Benefia: 'benefia',
  Generali: 'generali',
  Link4: 'link4',
  MTU24: 'mtu',
  MTU: 'mtu',
  Proama: 'proama',
  TuEuropa: 'tueuropa',
  'You Can Drive': 'ycd',
  PZU: 'pzu',
};

export const checkOfferPushToDataLayer = insuranceCompany => dataLayer.push({
  insuranceCompany: PARTNER_MAPPING[insuranceCompany],
  formType: 'HH',
  event: 'clickButtonBuyOnline',
});

export const leaveContactPushToDataLayer = (insuranceCompany, shortLocator) => dataLayer.push({
  insuranceCompany: PARTNER_MAPPING[insuranceCompany],
  formType: 'HH',
  shortLocator,
  event: 'contactFormDisplay',
});

export const sendLeadPushToDataLayer = (insuranceCompany, shortLocator) => dataLayer.push({
  insuranceCompany: PARTNER_MAPPING[insuranceCompany],
  formType: 'HH',
  shortLocator,
  event: 'contactFormLead',
});
